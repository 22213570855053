import { ToastAria, useToast } from "@react-aria/toast";
import { DOMProps } from "@react-types/shared";
import {
  CheckCircleIcon,
  CircleSlashIcon,
  CloseIcon,
  InfoIcon,
} from "ds/icons";
import { Button } from "ds/ui";
import { ElementType, useRef } from "react";
import { tv, type VariantProps } from "tailwind-variants";
import { ToastNotificationProps } from "../notification/notification";

type ToastBarVariant = VariantProps<typeof toastVariants>;
type ToastBarColor = Exclude<ToastBarVariant["color"], undefined>;

export type ToastBarContentType = DOMProps & {
  action?: {
    label: string;
    onClick?: () => void;
  };
  color: ToastBarColor;
  message: string;
};

const toastVariants = tv({
  //TODO replace shadow as soon as it was added to the config
  base: "flex items-center p-4 rounded-lg shadow-[0px_2px_6px_-1px_#21212126,_0px_8px_24px_-4px_#2121214D] text-white text-small gap-2 min-w-[400px] max-w-[600px] w-fit justify-between",
  variants: {
    color: {
      success: "bg-success",
      danger: "bg-danger",
      primary: "bg-primary",
    },
  },
  defaultVariants: {
    color: "primary",
  },
});

const ICONS: Record<ToastBarColor, ElementType> = {
  primary: InfoIcon,
  danger: CircleSlashIcon,
  success: CheckCircleIcon,
};

export const ToastBarContent = ({
  closeButtonProps,
  state,
  titleProps,
  toast: {
    content: { action, color, message },
    key,
  },
}: ToastNotificationProps<ToastBarContentType> &
  Pick<ToastAria, "closeButtonProps" | "titleProps">) => {
  const Icon = ICONS[color];

  return (
    <div className={toastVariants({ color })}>
      <div className="flex items-center gap-4">
        <Icon className="h-5 w-5 flex-shrink-0" />
        <p {...titleProps}>{message}</p>
      </div>
      <div className="flex items-center gap-2">
        {action && (
          <Button
            onClick={action.onClick}
            color={color}
            variant="solid"
            size="sm"
          >
            {action.label}
          </Button>
        )}
        <Button
          {...closeButtonProps}
          onClick={() => state.close(key)}
          size="xs"
          color={color}
          variant="solid"
          isIconOnly
        >
          <CloseIcon className="h-5 w-5" />
        </Button>
      </div>
    </div>
  );
};

export const ToastBar = ({
  state,
  ...props
}: ToastNotificationProps<ToastBarContentType>) => {
  const ref = useRef(null);
  const { closeButtonProps, contentProps, titleProps, toastProps } = useToast(
    props,
    state,
    ref,
  );

  return (
    <div ref={ref} {...toastProps}>
      <div {...contentProps}>
        <ToastBarContent
          state={state}
          closeButtonProps={closeButtonProps}
          titleProps={titleProps}
          toast={props.toast}
        />
      </div>
    </div>
  );
};

ToastBar.displayName = "RecareUI.ToastBarVariant";
